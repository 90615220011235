import img1 from "../img/img1.png";
import img2 from "../img/img2.png";
import img3 from "../img/img3.png";
import img4 from "../img/img4.jpg";

export const Followers = [
  { name: "Andrew Thomas", username: "AndrewThomas", img: img1 },
  { name: "Hulk Buster", username: "HulkBuster", img: img2 },
  { name: "Thor", username: "ThunderMaster", img: img3 },
  { name: "Natasha", username: "Natasha", img: img4 },
];