export const TrendData= [
    {
      name: "Minions",
      shares: 97,
    },
    {
      name: "Avangers",
      shares: 80.5,
    },
    {
      name: "Zainkeepscode",
      shares: 75.5,
    },
    {
      name: "Reactjs",
      shares: 72,
    },
    {
      name: "Elon Musk",
      shares: 71.9,
    },
    {
      name: "Need for Speed",
      shares: 20,
    },
  ];
  